@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&family=Prompt:wght@100;200;300;400;500;600;700&display=swap);
body {
    height: 100%;
}
.login {
    width: 100vw;
    height: 100vh;
    padding:15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form {
    max-width: 450px;
    width: 100%;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    padding: 2em;
    border-radius: 5px;
}
.pdf-docs .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pdf-docs .react-pdf__Page {
    max-width: calc(100% - 0em);
    /* width: 100% !important; */
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    margin: 1em;
}
.pdf-docs .react-pdf__Page canvas {
    /* width: 100% !important; */
    /* height: 100%!important; */
    max-width: 100%;
    height: auto !important;
}
.pdf-docs .react-pdf__message {
    padding: 20px;
    color: white;
}
nav.navbar .navbar-toggler:focus{
    box-shadow: unset;
}
nav.navbar .navbar-brand{
    max-width: 130px;
}
nav.navbar .menu-bar .nav-link{
    padding:10px 25px ;
    line-height: 1;
    text-transform: uppercase;
    border:1px solid transparent;
    transition: all 0.3s ease-in-out;
}
nav.navbar .menu-bar .nav-link span{
    display: inline-block;
    position: relative;
    border:1px solid transparent;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    font-weight: 500;
}
nav.navbar .menu-bar .nav-link span:before{
    content: "";
    position: absolute;
    bottom:-3px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #084298;
    transition: all 0.3s ease-in-out;
}
nav.navbar .menu-bar .nav-link:hover span,
nav.navbar .menu-bar .nav-link.active span{
    color: #084298;
}   
nav.navbar .menu-bar .nav-link.active span:before,
nav.navbar .menu-bar .nav-link:hover span:before{
    width: 100%;
}
nav.navbar .right-col .header-icon-link{
    padding: 5px 0;
    display: flex;
    align-items: center;
    margin-right: 25px;
    color: #486a91 !important;
}
nav.navbar .right-col .nav-link .header-icon{
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Prompt', sans-serif;
}
nav.navbar .right-col .rightcolmenu-wrap{
    display: flex;
    justify-content: space-between;
}
.upload-file .upload-file-label{
    margin-bottom: 10px;
}
.site-footer{
    margin-top: 20px;
    padding: 15px 0;
    background: #181819;
}
.site-footer .footer-logo{
    /* filter: contrast(0); */
    max-width: 130px;
}
.site-footer .footerMenuCol-wrap h4{ 
    color: #fff;
}
.site-footer .footerMenuCol-wrap ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
.site-footer .footerMenuCol-wrap ul a{
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
}
.site-footer .footerMenuCol-wrap ul a:hover{
    opacity: 1;
    font-size: 15px;
}
.content-wrapper{
    padding: 30px 0;
    min-height: calc(100vh - 139px);
    overflow: hidden;
}

@media only screen and (max-width: 991px) {
    nav.navbar .menu-bar .nav-link,
    nav.navbar .right-col .header-icon-link{
        padding: 10px 0;
        color: #486a91 !important;
    }
}
@media only screen and (max-width: 767px) {
    nav.navbar .right-col .rightcolmenu-wrap{
        flex-direction: column;
        margin-top: 10px;
    }
    nav.navbar .right-col .header-icon-link{
        padding: 5px 0;
        margin: 0;
        color: #486a91 !important;
    }
}

